import { useContext, ReactNode } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { Navigate } from 'react-router-dom';
import LoadingPageComponent from '../components/LoadingPage/LoadingPageComponent';

interface CompanyAuthProps {
    children: ReactNode;
}

export default function CompanyLoad({ children }: CompanyAuthProps): JSX.Element {

    const { company, isLoadingCompany } = useContext(CompanyContext);


    if (isLoadingCompany) {
        return (
            <LoadingPageComponent />
        )
    }

    if (!company) {
        return <Navigate to="/error" />
    }

    return children as JSX.Element
}