import { Box, Button, CircularProgress, MenuItem, Step, StepLabel, Stepper, styled, TextField, Tooltip, Alert, AlertTitle, Zoom } from "@mui/material";
import { useState, useEffect } from "react";
import { useCart } from "../../hook/useCart";
import CartProductComponent from "../CartProduct/CartProductComponent";
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { StepIconProps } from '@mui/material/StepIcon';

import './index.css'
import { api } from "../../lib/api";
import customToast from "../../toast/customToast";
import { LoadingButton } from "@mui/lab";
import FinishedOrderComponent from "../FinishedOrder/FinishedOrderComponent";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IMaskInput } from "react-imask";
import { NewClientComponent } from "../NewClient/NewClientComponent";


import IntlCurrencyInput, { IntlFormatterConfig } from "react-intl-currency-input";
import { useCompany } from "../../hook/useCompany";
import { EditClientComponent } from "../EditClient/EditClientComponent";
import { add, format } from "date-fns";
import { ptBR as ptBRfns } from "date-fns/locale";
import { ptBR } from '@mui/x-date-pickers/locales';

interface IUser {
    Id: number;
    CNPJCPF: string;
    Nome: string;
    Ativo: string;
    CEP: number;
    Endereco: string;
    Numero: string;
    Complemento: string;
    Bairro: string;
    Cidade: string;
    UF: string;
    Tel1: string;
    Tel2: string;
    Email: string;
    idEmpresa: number;
    idcidade?: number;
}

interface ICompany {
    Id: number;
    Razao: string;
    Fantasia: string;
    CNPJCPF: string;
    Cidade: string;
    UF: string;
    Status: string;
    Logo: string;
    Email: string;
    Tema: string;
    Endereco: string;
    CEP: string;
    Aberto: string;
    NomeCombo: string;
    HorarioMinimoRetirada?: Date;
    HorarioMaximoRetirada?: Date;
    MensagemFinalPedido?: string;
    MensagemWhatsapp?: string;
    CampoMensagemWhatsapp?: string;
    NumeroWhatsapp?: string;
    corPrimaria?: string;
    corSecundaria?: string;
    corTerciaria?: string;
}

interface IOrderItemComboSendToBackend {
    IdProduto: number;
    ex: number;
    idEmpresa: number;
}

interface IOrderItemSendToBackend {
    QtdeItens: number;
    QtdeProduto: number;
    ValorUnitario: string;
    idEmpresa: number;
    ex: number;
    IdProduto: number;
    FatorPeso?: number;
    orderItemsCombo: IOrderItemComboSendToBackend[];
}

interface IOrderSendToBackend {
    IdCliente: number;
    CEPEntrega: number;
    EnderecoEntrega: string;
    NumeroEntrega: string;
    ComplementoEntrega?: string;
    BairroEntrega: string;
    CidadeEntrega: string;
    UFEntrega: string;
    Data: Date;
    Hora: string | Date;
    HoraEntrega: string | Date;
    AgEntrega: string;
    Situacao: string;
    ValorFrete: number;
    ValorTotal: number;
    Troco: number;
    idEmpresa: number;
    TipoEntrega: string;
    FormaPagto: string;
    idcidade?: number;
    orderItems: IOrderItemSendToBackend[];
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#4C3225',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#4C3225',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#4C3225',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#4C3225',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
);

const currencyConfig: IntlFormatterConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export default function StepperCartComponent() {
    const steps = ['Carrinho', 'Identificação', 'Finalizar'];
    const [activeStep, setActiveStep] = useState(0);

    const [isCpfIdentify, setIsCpfIdentify] = useState<boolean>(true);

    const [client, setClient] = useState<IUser | null>(null);
    const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);
    const [deliveryType, setDeliveryType] = useState<string>("D");
    const [paymentMethod, setPaymentMethod] = useState<string>("D");
    const [freight, setFreight] = useState<number | null>(null);
    const [needChangeMoney, setNeedChangeMoney] = useState<boolean>(false);
    const [changeMoney, setChangeMoney] = useState<number>(0);
    const [cpf, setCpf] = useState('');
    const [phone, setPhone] = useState('');
    const [markedHour, setMarkedHour] = useState<Date | null>(new Date());

    const [isSendingOrder, setIsSendingOrder] = useState<boolean>(false);

    const { cartProducts, deleteCart } = useCart();
    const { company } = useCompany();

    // useEffect(() => {
    //     if (isCpfIdentify) {
    //         loadClientByCpf(userCpfCart);
    //     }
    //     else {
    //         loadClientByPhone(userPhoneCart);
    //     }
    // }, [])

    function getDateFromHours(time: string) {
        let splitedTime = time.split(':').map(Number);
        let now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...splitedTime);
    }


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleReset = async () => {
        await deleteCart();
        setActiveStep(0);
    };

    function changeIdentify(cpfIdentify: boolean) {
        setIsCpfIdentify(cpfIdentify);

        if (cpfIdentify) {
            // loadClientByCpf(cpf);
            setPhone('');
        }
        else {
            // loadClientByPhone(phone);
            setCpf('');
        }
    }

    function calcTotalCartValue() {
        let totalValue = 0;

        cartProducts.forEach(product => {

            if (product.fatorPeso > 0) {

                totalValue += product.price * product.fatorPeso;
            }
            else {
                totalValue += product.price;
            }

        })

        return totalValue;
    }

    async function calcFreight(client: IUser | null, deliveryType: string) {
        let freightResult: any;
        let cepFreightResult: any;

        if (client) {
            if (deliveryType === "D" || deliveryType === "R" || deliveryType === "M") {
                if (deliveryType === "D") {
                    setIsLoadingClient(true);
                    try {
                        freightResult = await api.get(`/district-freight/district/${client.Bairro}/cep/${client.CEP}/company/5`);
                        setFreight(Number(freightResult.data.Valor));
                    } catch (err) {
                        console.clear();
                        try {
                            cepFreightResult = await api.get(`/cep-freight/cep/${client.CEP}/company/5`);

                            setFreight(Number(cepFreightResult.data.Valor));
                        } catch (err) {
                            console.clear();
                            setFreight(null)
                        }

                    } finally {
                        setIsLoadingClient(false);
                    }
                }
                else {
                    setFreight(0);
                }
            }

        }
    }

    async function loadClientByCpf(cpf: string) {
        setFreight(null);
        setCpf(cpf);

        if (cpf.length === 11) {
            setIsLoadingClient(true);
            try {
                const res = await api.get(`/client/document/${cpf}`)

                setClient(res.data);
                await calcFreight(res.data, deliveryType);
            }
            catch (err) {
                console.clear();
                setClient(null);
            } finally {
                setIsLoadingClient(false);
            }
        }
        else {
            setClient(null);
        }
    }

    async function loadClientByPhone(value: string) {

        setFreight(null);
        setPhone(value);


        if (value.length === 11) {
            setIsLoadingClient(true);
            try {
                const res = await api.get(`/client/phone/${value}`)

                setClient(res.data);
                await calcFreight(res.data, deliveryType);
            }
            catch (err) {
                console.clear();
                setClient(null);
            } finally {
                setIsLoadingClient(false);
            }
        }
        else {
            setClient(null);
        }
    }

    async function sendOrder() {

        if (!client) {
            customToast.error('Não foi encontrar o cliente');
            return;
        }

        if (freight === null && deliveryType === "D") {
            customToast.error('Não fazemos entrega nesse endereço');
            return;
        }



        if (paymentMethod !== "D") {
            setChangeMoney(0);
        }
        else if (paymentMethod === "D" && !needChangeMoney) {
            setChangeMoney(0);
        }
        else {
            if (isNaN(changeMoney)) {
                customToast.error("O troco deve ser um valor válido");
                return;
            }
            if (changeMoney < (calcTotalCartValue() + (freight ? freight : 0))) {
                customToast.error("O valor para o troco não pode ser menor que o valor do carrinho");
                return;
            }
        }



        let items: IOrderItemSendToBackend[] = [];

        cartProducts.forEach(item => {

            items.push({
                QtdeItens: item.orderItemsCombo.length,
                QtdeProduto: item.quantity,
                ValorUnitario: item.fatorPeso > 0 ? (item.price / item.quantity * item.fatorPeso).toString() : (item.price / item.quantity).toString(),
                idEmpresa: 5,
                ex: 0,
                IdProduto: Number(item.id),
                FatorPeso: item.fatorPeso > 0 ? item.fatorPeso : undefined,
                orderItemsCombo: item.orderItemsCombo.map(value => {
                    return {
                        IdProduto: value.productId,
                        ex: 0,
                        idEmpresa: 5
                    }
                })
            })
        })

        let HoraEntrega: string | Date = '00:00:00';
        let TipoEntrega = deliveryType;
        let AgEntrega = 'N';

        if (deliveryType === 'M') {

            if (company?.HorarioMinimoRetirada == null || company?.HorarioMaximoRetirada == null) {
                customToast.error("Esse restaurante não permite agendar retirada");
                return;
            }

            if (markedHour == null) {
                customToast.error('Informe um horário para retirada do pedido');
                return;
            }

            HoraEntrega = markedHour.toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" });

            if (getDateFromHours(HoraEntrega) < getDateFromHours(company?.HorarioMinimoRetirada) || getDateFromHours(HoraEntrega) > add(getDateFromHours(company?.HorarioMaximoRetirada), { seconds: 59 })) {
                customToast.error(`O horário de entrega deve ser entre as ${format(getDateFromHours(company?.HorarioMinimoRetirada), 'HH:mm')} e ${format(getDateFromHours(company?.HorarioMaximoRetirada), 'HH:mm')}`);
                return;
            }

            if (add(getDateFromHours(HoraEntrega), { seconds: 59 }) < new Date()) {
                customToast.error(`Por favor, informe um horário maior que o horário atual.`);
                return;
            }

            TipoEntrega = 'R';
            AgEntrega = 'S';
        }


        const order: IOrderSendToBackend = {
            IdCliente: client.Id,
            CEPEntrega: client.CEP,
            EnderecoEntrega: client.Endereco,
            NumeroEntrega: client.Numero,
            BairroEntrega: client.Bairro,
            CidadeEntrega: client.Cidade,
            UFEntrega: client.UF,
            Data: new Date(),
            Hora: new Date().toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" }),
            HoraEntrega,
            AgEntrega,
            Situacao: "A",
            ValorFrete: (freight ? freight : 0),
            ValorTotal: calcTotalCartValue() + (freight ? freight : 0),
            Troco: changeMoney,
            idEmpresa: 5,
            idcidade: client.idcidade ? client.idcidade : undefined,
            TipoEntrega,
            FormaPagto: paymentMethod,
            orderItems: items
        }

        if (client.Complemento) {
            order.ComplementoEntrega = client.Complemento
        }


        setIsSendingOrder(true);


        try {

            const result = await api.get('/company/5');

            if (result.data.Aberto === "N") {
                customToast.error("Loja fechada no momento. Assim que abrirmos, o botão FINALIZAR PEDIDO ficará disponível.");
                return;
            }

            await api.post('/order', order);

            customToast.success("Pedido enviado com sucesso");

            localStorage.removeItem('cart');


            handleNext();
        } catch (err) {
            customToast.error("Erro ao enviar o pedido");
        } finally {
            setIsSendingOrder(false);
        }
    }

    function removeMask(value: string) {
        return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
    }

    function formatMoney(value: number) {
        return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    return (
        <div className="stepper-cart">
            <Stepper sx={{ padding: '10px 0' }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <div className="stepper-cart-content">
                    <div className="stepper-cart-content-group">
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FinishedOrderComponent handleReset={handleReset} />
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Voltar ao inicio</Button>
                        </Box>
                    </div>
                </div>
            )
                :
                activeStep === 0 ? (
                    <div className="stepper-cart-content">
                        <div className="stepper-cart-content-group">
                            <div className="cart-modal-products">
                                {
                                    cartProducts.map((product, index) => {
                                        return (
                                            <CartProductComponent key={`${index}-${product.id}`} id={product.id} description={product.description} price={product.price} quantity={product.quantity} image={product.image} cartIndex={index} orderItemsCombo={product.orderItemsCombo} fatorPeso={product.fatorPeso} calcTotalCartValue={calcTotalCartValue} />
                                        )
                                    })
                                }
                            </div>
                            <div className="cart-total-value-group">
                                <div className="cart-total-value">
                                    <div className="cart-total-value-title">
                                        <i className="fa-solid fa-sack-dollar"></i>
                                        <div>Valor Total</div>
                                        <Tooltip enterTouchDelay={0} TransitionComponent={Zoom} title="Pode haver alteração no valor conforme taxa de entrega" placement="top">
                                            <span>
                                                <i className="fa-solid fa-circle-info"></i>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="cart-total-value-price">R$ {formatMoney(calcTotalCartValue())}</div>
                                </div>
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Voltar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                </Button>
                            </Box>
                        </div>

                    </div>
                )
                    :
                    activeStep === 1 ? (
                        <div className="stepper-cart-content">
                            <div className="stepper-cart-content-group">
                                <div className="stepper-cart-content-group-info">
                                    <div className="stepper-cart-content-group-container">
                                        <div style={{ fontSize: 14 }}> <i className="fa-solid fa-id-card-clip"></i> Identificar por:</div>
                                        <div className="stepper-cart-content-group-options">
                                            <div className="stepper-cart-content-group-custom-radio">
                                                <input type="radio" name="document" id="cpf" value="cpf" disabled={isLoadingClient} checked={isCpfIdentify} onChange={() => changeIdentify(true)} />
                                                <label htmlFor="cpf">CPF</label>
                                            </div>
                                            <div className="stepper-cart-content-group-custom-radio">
                                                <input type="radio" name="document" id="phone" value="phone" disabled={isLoadingClient} checked={!isCpfIdentify} onChange={() => changeIdentify(false)} />
                                                <label htmlFor="phone">Telefone</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stepper-cart-content-group-identity-input">
                                        {
                                            <>
                                                <div className="stepper-cart-input-group" style={{ display: isCpfIdentify ? 'block' : 'none' }}>
                                                    <label>CPF</label>
                                                    <IMaskInput placeholder="Informe um CPF" mask="000.000.000-00" value={cpf} onChange={() => { }} onAccept={value => loadClientByCpf(removeMask(`${value}`))} />
                                                </div>
                                                <div className="stepper-cart-input-group" style={{ display: !isCpfIdentify ? 'block' : 'none' }}>
                                                    {/* <TextField fullWidth id="outlined-basic" value={phone} label="Telefone" variant="outlined" onChange={e => loadClientByPhone(e)} /> */}
                                                    <label>Telefone</label>
                                                    <IMaskInput placeholder="Informe um telefone/celular" label="Telefone" mask="(00)00000-0000" value={phone} onChange={() => { }} onAccept={value => loadClientByPhone(removeMask(`${value}`))} />
                                                    {/* <IMaskInput label="Telefone" mask="(00)00000-0000" value={phone} onChange={() => { }} onAccept={value => loadClientByPhone(removeMask(`${value}`))} /> */}
                                                </div>
                                            </>

                                        }
                                        {
                                            (isCpfIdentify && cpf.length === 11 && !isLoadingClient && !client) || (!isCpfIdentify && phone.length >= 10 && phone.length <= 11 && !isLoadingClient && !client) ?
                                                <NewClientComponent loadClient={loadClientByCpf} cpfProps={cpf} phoneProps={phone} />
                                                :
                                                (isCpfIdentify && cpf.length === 11 && !isLoadingClient && client) ?
                                                    <EditClientComponent loadClient={loadClientByCpf} cpfProps={cpf} />
                                                    :
                                                    (!isCpfIdentify && phone.length >= 10 && phone.length <= 11 && !isLoadingClient && client) ?
                                                        <EditClientComponent loadClient={loadClientByCpf} phoneProps={phone} />
                                                        :
                                                        ''
                                        }
                                    </div>



                                    <div>
                                        <TextField select label="Retirada/Delivery" value={deliveryType} onChange={e => (setDeliveryType(e.target.value), calcFreight(client, e.target.value))} fullWidth>
                                            <MenuItem value="R">Retirar no local</MenuItem>
                                            <MenuItem value="D">Delivery</MenuItem>
                                            {
                                                company?.HorarioMinimoRetirada && company.HorarioMaximoRetirada &&
                                                <MenuItem value="M">Retirar no local com hora marcada</MenuItem>
                                            }
                                        </TextField>
                                    </div>
                                    {
                                        deliveryType === 'M' && company?.HorarioMinimoRetirada && company?.HorarioMaximoRetirada &&
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>

                                                <div> <i className="fa-regular fa-clock"></i> Horário de retirada (entre {format(getDateFromHours(company?.HorarioMinimoRetirada), 'HH:mm')} e {format(getDateFromHours(company?.HorarioMaximoRetirada), 'HH:mm')})</div>
                                                <DemoContainer
                                                    components={[
                                                        'MobileTimePicker',
                                                    ]}
                                                >
                                                    <MobileTimePicker label="Selecione um horário" disablePast minTime={getDateFromHours(company?.HorarioMinimoRetirada)} maxTime={add(getDateFromHours(company?.HorarioMaximoRetirada), { seconds: 59 })} value={markedHour} onChange={(time) => setMarkedHour(time)} sx={{ width: '100%' }} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    }

                                    <div>
                                        <TextField select label="Forma de pagamento" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)} fullWidth>
                                            <MenuItem value="C">Cartão de crédito/débito</MenuItem>
                                            <MenuItem value="D">Dinheiro</MenuItem>
                                        </TextField>
                                    </div>
                                    {
                                        paymentMethod === "D" &&
                                        <div>
                                            <small>Precisa de troco?</small>
                                            <div className="change-money-question-group">
                                                <div className="change-money-question-group-option">
                                                    <input id="troco" type="radio" name="needChangeMoney" checked={needChangeMoney} onChange={() => setNeedChangeMoney(true)} />
                                                    <label htmlFor="troco">Sim, preciso</label>
                                                </div>
                                                <div className="change-money-question-group-option">
                                                    <input id="sem-troco" type="radio" name="needChangeMoney" checked={!needChangeMoney} onChange={() => setNeedChangeMoney(false)} />
                                                    <label htmlFor="sem-troco">Não preciso</label>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        paymentMethod === "D" && needChangeMoney &&
                                        <div className="stepper-cart-input-group">
                                            {/* <TextField fullWidth id="outlined-basic" type="number" value={changeMoney} onChange={e => setChangeMoney(Number(e.target.value))} label="Troco para" variant="outlined" /> */}
                                            <IntlCurrencyInput currency="BRL" value={changeMoney} config={currencyConfig} max={99999} onChange={(event, value, maskedValue) => setChangeMoney(Number(value))} />
                                        </div>
                                    }

                                    {
                                        isLoadingClient ?
                                            <CircularProgress sx={{ margin: 'auto' }} />
                                            :
                                            client ?
                                                <>
                                                    {
                                                        !(freight === null && deliveryType === "D") ?
                                                            <>
                                                                <div className="stepper-cart-content-group-user-info">
                                                                    <div>
                                                                        <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-user"></i> Nome</div>
                                                                        <div className="stepper-cart-content-group-user-info-value">{client.Nome} </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Taxa de entrega</div>
                                                                        <div className="stepper-cart-content-group-user-info-value">R$ {freight && formatMoney(freight)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="stepper-cart-content-group-user-info">
                                                                    <div>
                                                                        <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Endereço</div>
                                                                        <div className="stepper-cart-content-group-user-info-value">{client.Endereco}, {client.Bairro}, {client.Cidade}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <Alert severity="warning">
                                                                <AlertTitle> <b>Aviso</b></AlertTitle>
                                                                Serviço de delivery indisponível nesse endereço. Selecione a opção para retirar no local.
                                                            </Alert>
                                                    }
                                                </>
                                                :
                                                <Alert severity="error">Informe um CPF ou Telefone que seja cadastrado em <b>{company?.Fantasia}</b></Alert>

                                    }

                                </div>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Voltar
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    <Button onClick={handleNext} disabled={!client || (freight === null && deliveryType === "D")} >
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    )
                        :
                        activeStep === 2 ? (
                            <div className="stepper-cart-content">
                                <div className="stepper-cart-content-group">
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-user"></i> Nome</div>
                                                <div className="stepper-cart-content-group-user-info-value">{client?.Nome}</div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Endereço</div>
                                                <div className="stepper-cart-content-group-user-info-value">{client?.Endereco}, {client?.Numero}, {client?.Bairro}, {client?.Cidade}</div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-wallet"></i> Pagamento</div>
                                                <div className="stepper-cart-content-group-user-info-value">{paymentMethod === "C" ? "Cartão de crédito/débito" : "Dinheiro"}</div>
                                            </div>
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-dollar-sign"></i> Troco para</div>
                                                <div className="stepper-cart-content-group-user-info-value">
                                                    {
                                                        !needChangeMoney ?
                                                            "Sem troco"
                                                            :
                                                            <>
                                                                R$ {formatMoney(changeMoney)}
                                                            </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-tag"></i> Tipo</div>
                                                <div className="stepper-cart-content-group-user-info-value">{deliveryType === "D" ? "Entrega" : "Retirada no local"}</div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"> <i className="fa-solid fa-cart-shopping"></i> Produtos</div>
                                                <div className="stepper-cart-content-group-user-info-order">
                                                    {
                                                        cartProducts.map((cartProduct, index) => {
                                                            return (
                                                                <div key={index} className="stepper-cart-content-group-user-info-order-product">
                                                                    <div>{cartProduct.fatorPeso > 0 ? `${(cartProduct.quantity * cartProduct.fatorPeso).toFixed(3)}kg` : `${cartProduct.quantity}x`} - {(cartProduct.description).toLocaleLowerCase()}</div>
                                                                    <div className="stepper-cart-content-group-user-info-order-product-price">R$ {cartProduct.fatorPeso > 0 ? formatMoney(cartProduct.price * cartProduct.fatorPeso) : formatMoney(cartProduct.price)}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="stepper-cart-content-group-user-info-order-subtotal">
                                                        <div style={{ fontWeight: 'initial' }}> <i className="fa-solid fa-coins"></i> Subtotal</div>
                                                        <div className="stepper-cart-content-group-user-info-order-subtotal-price">R$ {formatMoney(calcTotalCartValue())}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-motorcycle"></i> Taxa de entrega</div>
                                                <div className="stepper-cart-content-group-user-info-value">R$ {freight ? formatMoney(freight) : 0}</div>
                                            </div>
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-coins"></i> Valor total</div>
                                                <div className="stepper-cart-content-group-user-info-value">R$ {freight ? (formatMoney(calcTotalCartValue() + freight)) : formatMoney(calcTotalCartValue())}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Voltar
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />

                                        <LoadingButton loading={isSendingOrder} onClick={sendOrder}>
                                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                        </LoadingButton>
                                    </Box>
                                </div>

                            </div>
                        )
                            :
                            <div>Err</div>
            }

        </div >

    )
}