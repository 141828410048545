import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Orders from "../pages/Orders";
import CompanyLoad from "../middlewares/CompanyLoad";
import NotFound from "../pages/NotFound/NotFound";

export default function RoutesApp() {
    return (
        <Routes>
            <Route path="/" element={<CompanyLoad> <Home /> </CompanyLoad>} />
            <Route path="/orders" element={<CompanyLoad> <Orders /> </CompanyLoad>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}