import SuccessBadgeImage from '../../assets/success-badge.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

interface FinishedOrderProps {
    handleReset: () => void;
}

export default function FinishedOrderComponent({ handleReset }: FinishedOrderProps) {


    useEffect(() => {
        setTimeout(() => {
            handleReset();
        }, 5000)
    }, [])

    return (
        <div className='success-container'>
            <div className='success-badge-group'>
                <div className='success-badge'>
                    <img src={SuccessBadgeImage} alt="" />
                </div>
                <div className='success-badge-text'>O pedido foi enviado ao restaurante</div>

            </div>
            <div className='success-loader-group'>
                <div className='success-loader-group-loader'>
                    <div className='success-loader-group-loader-fill'></div>
                </div>
            </div>
        </div>


    )
}