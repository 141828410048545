
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';

export default function LoadingPageComponent() {
    return (
        <div className='loading-page-content'>
            <CircularProgress />
        </div>
    )
}