import { Link } from 'react-router-dom'
import CartComponent from '../Cart/CartComponent'
import './index.css'
import { useCart } from '../../hook/useCart'

export default function NavBarComponent() {

    const { setIsVisibleCart } = useCart();

    return (
        <nav className='kaelex-navbar'>
            <ul>
                <li>
                    <Link to="/" onClick={() => setIsVisibleCart(false)}>
                        <i className="fas fa-house"></i>
                        <p>Inicio</p>
                    </Link>
                </li>
                <li>
                    <Link to="/orders" onClick={() => setIsVisibleCart(false)}>
                        <i className="fas fa-scroll"></i>
                        <p>Pedidos</p>
                    </Link>
                </li>
                <CartComponent />
            </ul>
        </nav>
    )
}