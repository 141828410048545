import './index.css';

export default function EmptyCartComponent() {
    return (
        <div className="empty-cart">
            <div className='empty-cart-image'>
                <img src="https://indyme.com/wp-content/uploads/2020/11/shopping-cart-icon.png" alt="" />
            </div>
            <div className='empty-cart-body'>
                <div className='empty-cart-body-title'>O carrinho está vazio</div>
                <div className='empty-cart-body-subtitle'>Adicione produtos para vê-los aqui!</div>
            </div>
        </div>
    )
}