import './index.css';
import { useState, useEffect } from "react";

interface IOrderStatusBarComponent {
    status: string;
}

enum OrderStatus {
    EFETUADO = 'A',
    CANCELADO = 'C',
    ENTREGA = 'B',
    PAGO = 'P',
}

export default function OrderStatusBarComponent({ status }: IOrderStatusBarComponent) {

    const [color, setColor] = useState<string>('#FFF');
    const [width, setWidth] = useState<number>(0);
    const [text, setText] = useState<string>('');
    const [icon, setIcon] = useState<string>('');

    useEffect(() => {
        if (status == OrderStatus.EFETUADO) {
            setColor('#ffa502');
            setWidth(30);
            setText('Seu pedido foi enviado');
            setIcon('fas fa-clock')
        }
        else if (status == OrderStatus.CANCELADO) {
            setColor('#e74c3c');
            setWidth(100);
            setText('Seu pedido foi cancelado');
            setIcon('fas fa-ban')
        }
        else if (status == OrderStatus.ENTREGA) {
            setColor('#3742fa');
            setWidth(100);
            setText('Seu pedido está em rota de entrega');
            setIcon('fas fa-motorcycle')
        }
        else if (status == OrderStatus.PAGO) {
            setColor('#2ecc71');
            setWidth(100);
            setText('Seu pedido foi finalizado');
            setIcon('fas fa-check-circle')
        }
    }, [])

    return (
        <div className="order-status-bar">
            <div className="order-status-bar-group">
                <div className="order-status-bar-group-background">
                    <div className='order-status-bar-group-fill' style={{ backgroundColor: color, width: `${width}%` }}></div>
                </div>
            </div>
            <div className="order-status-bar-title" style={{ color, fontWeight: 'bold' }}>
                <i className={icon}></i>{text}
            </div>
        </div>
    )
}