import { createContext, ReactNode, useEffect, useState } from 'react';
import { api } from '../lib/api';

interface ComboItemInCartProps {
    productId: number;
    description: string;
}

export interface CartProductProps {
    id: number;
    description: string;
    quantity: number;
    price: number;
    fatorPeso: number;
    image: string | null;
    orderItemsCombo: ComboItemInCartProps[];
}

export interface CartContextDataProps {
    cartProducts: CartProductProps[];
    isLoadingCartProducts: boolean;
    isVisibleCart: boolean;
    userOrder: string;
    // isCpfIdentify: boolean;
    // setIsCpfIdentify: (status: boolean) => void;
    // userCpfCart: string;
    // setUserCpfCart: (user: string) => void;
    // userPhoneCart: string;
    // setUserPhoneCart: (user: string) => void;
    setUserOrder: (user: string) => void;
    setIsVisibleCart: (status: boolean) => void;
    // loadCart: () => Promise<void>;
    addCartProduct: (id: number, quantity: number, fatorPeso: number, comboItems: ComboItemInCartProps[]) => Promise<void>;
    removeCartProduct: (id: number, index: number) => Promise<void>;
    deleteCart: () => Promise<void>;
}

interface CartProviderProps {
    children: ReactNode;
}

export const CartContext = createContext({} as CartContextDataProps);


export function CartContextProvider({ children }: CartProviderProps) {
    const [cartProducts, setCartProducts] = useState<CartProductProps[]>([]);
    const [isLoadingCartProducts, setIsLoadingCartProducts] = useState<boolean>(false);

    const [isVisibleCart, setIsVisibleCart] = useState<boolean>(false);

    const [userOrder, setUserOrder] = useState<string>('');


    useEffect(() => {

    }, [])

    async function removeCartProduct(id: number, indexToDelete: number) {
        setIsLoadingCartProducts(true);

        let items: CartProductProps[] = cartProducts;

        let removedItem = items.filter((item, index) => index !== indexToDelete);

        setCartProducts(removedItem);

        setIsLoadingCartProducts(false);
    }

    async function deleteCart() {
        setIsLoadingCartProducts(true);

        setCartProducts([]);

        setIsLoadingCartProducts(false);
    }

    async function addCartProduct(id: number, quantity: number, fatorPeso: number, comboItems: ComboItemInCartProps[]) {

        let productsInCart: CartProductProps[] = cartProducts;

        try {

            const res = await api.get(`/product/${id}`);

            setCartProducts([...productsInCart, {
                id: res.data.Id,
                description: res.data.Descricao,
                quantity: quantity,
                price: quantity * res.data.Valor,
                fatorPeso: fatorPeso,
                image: res.data.Imagem,
                orderItemsCombo: comboItems
            }]);

        } catch (err) {
            console.log(err);
        } finally {

        }

    }

    return (
        <CartContext.Provider
            value={{
                cartProducts,
                isLoadingCartProducts,
                isVisibleCart,
                setIsVisibleCart,
                userOrder,
                setUserOrder,
                addCartProduct,
                removeCartProduct,
                deleteCart
            }}
        >
            {children}
        </CartContext.Provider>
    )
}