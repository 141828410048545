import { Alert, AlertTitle, Box, CircularProgress, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import ProductCardComponent, { ProductProps } from '../../components/ProductCardComponent/ProductCardComponent';
import { api } from '../../lib/api';
import './Home.css';
import Chip from '@mui/material/Chip';
import { useCompany } from '../../hook/useCompany';
import customToast from '../../toast/customToast';

export interface GroupProps {
    Id: number;
    Descricao: string;
    ex: string;
    Visivel: string;
    Ordem: number;
    products: ProductProps[];
}

interface ComboProps {
    ID: number;
    Nome: string;
    Ex: string;
    imempresa: number;
    Ordem: number;
    products: ProductProps[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Home() {
    const [value, setValue] = useState<string>('combo-0');
    const [groups, setGroups] = useState<GroupProps[]>([]);
    const [combo, setCombo] = useState<ComboProps[]>([]);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const { company } = useCompany();


    useEffect(() => {
        loadGroups();
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    async function loadGroups() {
        setIsLoadingData(true);
        try {
            const res = await api.get('/group');
            const comboRes = await api.get('/combo');

            var groups: GroupProps[] = res.data;
            var combos: ComboProps[] = comboRes.data;

            // Filtra somente os grupos que possuam produtos
            groups = groups.filter(group => group.products.length > 0);
            combos = combos.filter(combos => combos.products.length > 0);

            setCombo(combos);
            setGroups(groups);

            if (combos.length < 1) {
                setValue(`group-0`);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingData(false);
        }
    }

    async function btnNextStep() {
        // Pega o Grupo (Combo ou Grupo normal) e Indice do Step ativo atual
        var currentGroupType = value.split('-')[0];
        var currentGroupIndex = value.split('-')[1];

        // Verifica se a Tab ativa no momento pertence ao Combo
        if (currentGroupType === 'combo') {
            // Verifica se existe algum combo à frente
            if (Number(currentGroupIndex) < (combo.length - 1)) {
                setValue(`combo-${Number(currentGroupIndex) + 1}`);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
                if (groups.length > 0) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setValue(`group-0`);
                }
            }
        }
        else {
            if (Number(currentGroupIndex) < (groups.length - 1)) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setValue(`group-${Number(currentGroupIndex) + 1}`);
            }
        }
    }

    function btnBackStep() {
        // Pega o Grupo (Combo ou Grupo normal) e Indice do Step ativo atual
        var currentGroupType = value.split('-')[0];
        var currentGroupIndex = value.split('-')[1];

        // Verifica se a Tab ativa no momento pertence ao Combo
        if (currentGroupType === 'group') {
            // Verifica se existe algum combo à frente
            if (Number(currentGroupIndex) > 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setValue(`group-${Number(currentGroupIndex) - 1}`);
            }
            else {
                if (combo.length > 0) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setValue(`combo-${combo.length - 1}`);
                }
            }
        }
        else {
            if (Number(currentGroupIndex) > 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setValue(`combo-${Number(currentGroupIndex) - 1}`);
            }
        }
    }

    return (
        <div className="kaelex-main">
            <div className='kaelex-main-header'>
                <div className='kaelex-main-header-first-group'>
                    <div>
                        <div className='kaelex-main-header-logo'>
                            <img src={`${process.env.REACT_APP_IMAGE_URL}/${company?.Logo}`} alt="" />
                        </div>
                    </div>
                    <div className='kaelex-main-header-info'>

                        <div className='kaelex-main-header-info-head'>
                            <div className='kaelex-main-header-info-title'>{company?.Fantasia}</div>
                            {
                                company?.Aberto === "S" ?
                                    <Chip size='small' label="Aberto para pedidos" sx={{ backgroundColor: '#27ae60', color: 'white' }} />
                                    :
                                    <Chip size='small' label="Fechado para pedidos" sx={{ backgroundColor: '#d01b22', color: 'white' }} />

                            }
                        </div>
                        <div className='kaelex-main-header-info-status'>
                            <div className='kaelex-main-header-info-status-item'>
                                <div className='kaelex-main-header-info-status-icon'><i className="fa-solid fa-location-dot"></i></div>
                                {company?.Cidade} - {company?.UF}
                            </div>
                            {
                                company?.NumeroWhatsapp !== "" && company?.NumeroWhatsapp !== null &&
                                <>
                                    &#x2022;
                                    <div className='kaelex-main-header-info-status-item'>
                                        <div className='kaelex-main-header-info-status-icon'><i className="fa-solid fa-phone"></i></div>
                                        {company?.NumeroWhatsapp}
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </div>
            {
                isLoadingData
                    ?
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                TabIndicatorProps={{ style: { backgroundColor: '#4C3225' } }}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {

                                    combo.map((combo, index) => {
                                        return (

                                            <Tab value={`combo-${index}`} key={`combo-${combo.ID}`} label={(combo.Nome).toLowerCase()} {...a11yProps(combo.ID)} sx={{ color: '#4C3225 !important', textTransform: 'capitalize', fontWeight: '700', fontSize: 14, lineHeight: '14px', letterSpacing: '0.4px', fontFamily: 'Inter' }} />
                                        )
                                    })
                                }
                                {
                                    groups.map((group, index) => {
                                        return (

                                            <Tab value={`group-${index}`} key={`group-${group.Id}`} label={(group.Descricao).toLowerCase()} {...a11yProps(group.Id)} sx={{ color: '#4C3225 !important', textTransform: 'capitalize', fontWeight: '700', fontSize: 14, lineHeight: '14px', letterSpacing: '0.4px', fontFamily: 'Inter' }} />
                                        )
                                    })
                                }
                            </Tabs>
                        </Box>
                        {
                            combo.map((combo, index) => {
                                return (

                                    <TabPanel key={`tabpanel-combo-${combo.ID}`} value={`${value}`} index={`combo-${index}`}>


                                        {
                                            combo.products.length > 0
                                                ?
                                                <div className='products-container'>
                                                    {
                                                        combo.products.map(product => {
                                                            return (
                                                                <ProductCardComponent key={`combo-${product.Id}`} product={product} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <Alert severity="warning">
                                                    <AlertTitle> <b>Aviso</b></AlertTitle>
                                                    Essa categoria ainda não possui produtos
                                                </Alert>
                                        }


                                    </TabPanel>

                                )
                            })
                        }
                        {
                            groups.map((group, index) => {
                                return (
                                    <TabPanel key={`tabpanel-group-${group.Id}`} value={`${value}`} index={`group-${index}`}>
                                        {
                                            group.products.length > 0
                                                ?
                                                <div className='products-container'>
                                                    {
                                                        group.products.map(product => {
                                                            return (
                                                                <ProductCardComponent key={`group-${product.Id}`} product={product} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <Alert severity="warning">
                                                    <AlertTitle> <b>Aviso</b></AlertTitle>
                                                    Essa categoria ainda não possui produtos
                                                </Alert>
                                        }


                                    </TabPanel>

                                )
                            })
                        }
                    </Box>
            }
            <div className='kaelex-main-footer'>
                <div className='kaelex-main-footer-buttons'>
                    <button onClick={btnBackStep} className='kaelex-main-footer-buttons-btn-back'>
                        <i className="fa-solid fa-angle-left"></i>
                    </button>
                    <button onClick={btnNextStep} className='kaelex-main-footer-buttons-btn-next'>
                        <i className="fa-solid fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div >
    )
}