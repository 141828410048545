import { CircularProgress } from "@mui/material";
import { useEffect, useState } from 'react';
import { useCart } from '../../hook//useCart';

import './index.css'
import EmptyCartComponent from "../EmptyCart/EmptyCartComponent";
import StepperCartComponent from "../StepperCart/StepperCartComponent";

export default function CartComponent() {

    // const [isVisible, setIsVisible] = useState(false);

    const { isLoadingCartProducts, cartProducts, isVisibleCart, setIsVisibleCart } = useCart();

    useEffect(() => {
        if (isVisibleCart) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'initial';
        }
    }, [isVisibleCart]);

    function openDialog() {
        setIsVisibleCart(!isVisibleCart);
    }

    function closeDialog() {
        setIsVisibleCart(false);
    }

    function calcTotalCartValue() {
        let totalValue = 0;

        cartProducts.forEach(product => {
            totalValue += product.price;
        })

        return totalValue;
    }

    return (
        <div>
            <li onClick={openDialog}>
                {
                    isVisibleCart ?
                        <a href="#"> <i className="fa-solid fa-xmark"></i> </a>
                        :

                        <>
                            <a href="#">
                                <i className="fa-solid fa-cart-shopping"></i>
                                <p>Carrinho</p>
                            </a>

                            {
                                cartProducts.length > 0
                                &&
                                <div className="badge-count">{cartProducts.length}</div>
                            }
                        </>
                }


            </li>


            <div className={`cart-modal-background ${isVisibleCart && "active"} `}>
                <div className='close-modal' onClick={closeDialog}></div>
                <div className='cart-modal'>
                    {
                        isLoadingCartProducts
                            ?
                            <div className="cart-modal-loading">
                                <CircularProgress />
                            </div>
                            :

                            cartProducts.length === 0
                                ?
                                <div className="cart-modal-empty-cart-group">
                                    <EmptyCartComponent />
                                </div>
                                :
                                <StepperCartComponent />


                    }
                </div>
            </div>
        </div>
    )
}