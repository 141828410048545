import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import RoutesApp from './routes';
import NavBarComponent from './components/NavBar/NavBarComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartContextProvider } from './context/CartContext';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import FooterComponent from './components/Footer/FooterComponent';
import { CompanyContextProvider } from './context/CompanyContext';

function App() {
  return (
    <CompanyContextProvider>
      <CartContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
            <div className='app-content'>
              <NavBarComponent />
              <ToastContainer autoClose={3000} theme="colored" />
              <RoutesApp />
              <FooterComponent />
            </div>

          </BrowserRouter>
        </LocalizationProvider>
      </CartContextProvider>
    </CompanyContextProvider>

  );
}

export default App;
